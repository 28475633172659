export const columnsMock = [
  {
    name: "Conexao_1",
    children: [
      {
        name: "NUMERO",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "E-MAIL",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "CEP",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "DOCUMENTO",
        condiction: "",
        resp: "",
        auxId: "",
      },
    ],
  },
  {
    name: "Conexao_2",
    children: [
      {
        name: "NUMERO",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "E-MAIL",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "CEP",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "DOCUMENTO",
        condiction: "",
        resp: "",
        auxId: "",
      },
    ],
  },
  {
    name: "Conexao_3",
    children: [
      {
        name: "NUMERO",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "E-MAIL",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "CEP",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "DOCUMENTO",
        condiction: "",
        resp: "",
        auxId: "",
      },
    ],
  },
  {
    name: "Conexao_4",
    children: [
      {
        name: "NUMERO",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "E-MAIL",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "CEP",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "DOCUMENTO",
        condiction: "",
        resp: "",
        auxId: "",
      },
    ],
  },
  {
    name: "Conexao_5",
    children: [
      {
        name: "NUMERO",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "E-MAIL",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "CEP",
        condiction: "",
        resp: "",
        auxId: "",
      },
      {
        name: "DOCUMENTO",
        condiction: "",
        resp: "",
        auxId: "",
      },
    ],
  },
];

export const treeElementsMock = {
  conditions: [
    {
      field: "email",
      type: "equals",
      value: "test@zooxsmart.com.br",
      auxId: "lçkjhsd4",
    },
  ],
  groupId: "012345",
  nextGroupId: "0987565",
  condictionToNext: "AND",
  nextGroup: {
    conditions: [
      {
        field: "cpf",
        type: "equals",
        value: "12332112333",
        auxId: "lçkjhsd",
      },
    ],
    groupId: "0987565",
    nextGroupId: null,
    condictionToNext: null,
    nextGroup: null,
  },
};

// export const elementsGroups = {
//   groups: [
//     {
//       auxId: "primer-id",
//       conditions: [
//         {
//           field: "email",
//           type: "equals",
//           value: "gabriel@keener.io",
//           auxId: "2f234",
//           condictionRelation: null,
//         },
//       ],
//       relation: "OR",
//     },
//     {
//       auxId: "second-id",
//       conditions: [
//         {
//           field: "name",
//           type: "equals",
//           value: "some name",
//           auxId: "2ffasd234",
//           condictionRelation: null,
//         },
//       ],
//       relation: null,
//     },
//     // {
//     //   auxId: "third-id",
//     //   conditions: [],
//     //   relation: null,
//     // },
//   ],
// };

export const elementsGroups = {
  groups: [
    {
      auxId: "primer-id-000-111-ccc",
      conditions: [],
      relation: null,
    },
  ],
};
