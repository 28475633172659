<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="wrapper">
    <div
      class="content"
      @drop.prevent="onDrop($event)"
      @dragenter.prevent
      @dragover.prevent
    >
      <template v-if="node.conditions.length">
        <BlockListLine
          v-for="(item, idx) in node.conditions"
          :key="item.auxId"
          :info="item"
          :itemIndexProp="idx"
          :isLastChildProp="isLastChild(item)"
          @deleteLine="deleteLine"
          @pickElement="pickupElement"
          @onDropItem="onDropItem"
          @loadingLargeData="loadingLargeData"
        />
      </template>
      <template v-else>
        <div class="no-data">
          <span>Arraste a coluna para filtrar</span>
        </div>
      </template>
    </div>

    <div v-if="!isLastGroupProp" class="content__condiction mb-4">
      <v-row class="d-flex justify-space-between">
        <v-col cols="12" sm="12" md="2" lg="2">
          <z-select
            v-model="node.relation"
            :items="itemsBlock"
            itemText="text"
            itemValue="value"
            type="text"
            :hideDetails="true"
          />
        </v-col>

        <v-col class="d-flex justify-end" cols="12" sm="12" md="2" lg="2">
          <v-icon @click="deleteGroupProp(node)">mdi-delete</v-icon>
        </v-col>
      </v-row>
    </div>
    <div v-else class="content__condiction">
      <span @click="createGroupProp(node)">+ Adicionar novo grupo</span>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
import BlockListLine from "./blockListLine.vue";
export default {
  name: "NodeItem",
  props: {
    node: { type: Object, default: null },
    pickupElementProp: { type: Function, default: null },
    isLastGroupProp: { type: Boolean, default: false },
    createConditionNodeProp: { type: Function, default: null },
    deleteLineProp: { type: Function, default: null },
    createGroupProp: { type: Function, default: null },
    deleteGroupProp: {
      type: Function,
      default: null,
    },
  },
  components: {
    BlockListLine,
  },
  // inject: ["columnsMock"],
  inject: ["dbConfigTransformedList", "templateActionsList", "tagActionsList"],
  data() {
    return {
      http: new ApiClient(),
      draggedItem: null,
      itemsBlock: [
        { text: "OU", value: "OR" },
        { text: "E", value: "AND" },
      ],
      booleanColumns: [
        {
          text: "true",
          value: 1,
        },
        {
          text: "false",
          value: 0,
        },
        {
          text: "null",
          value: null,
        },
      ],
    };
  },
  watch: {
    // node: {
    //   handler(nv) {
    //     console.log(nv);
    //   },
    //   deep: true,
    // },
  },
  methods: {
    loadingLargeData(data) {
      this.$emit("loadingLargeData", data);
    },
    deleteLine(item) {
      this.deleteLineProp(item, this.node);
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    pickupElement(evt, el) {
      this.draggedItem = { ...el };
      evt.dataTransfer.setData("itemID", this.draggedItem.auxId);
    },
    onDrop(event) {
      const ITEM_ID = event.dataTransfer.getData("itemID");
      const FINDED_ITEM = this.node.conditions.find((el) => el.auxId == ITEM_ID);
      const IS_ORDENING_LIST =
        FINDED_ITEM == null || FINDED_ITEM == undefined ? false : true;

      if (!IS_ORDENING_LIST) {
        let FATHER_NODE = this.dbConfigTransformedList.find((i) =>
          i.children?.find((row) => row.auxId == ITEM_ID)
        );

        if (!FATHER_NODE) {
          FATHER_NODE = this.templateActionsList.find((i) =>
            i.children?.find((row) => row.auxId == ITEM_ID)
          );
        }

        if (!FATHER_NODE) {
          FATHER_NODE = this.tagActionsList.find((i) =>
            i.children?.find((row) => row.auxId == ITEM_ID)
          );
        }

        const CHILD_NODE = FATHER_NODE?.children?.find((j) => j.auxId == ITEM_ID);

        if (CHILD_NODE) {
          this.createConditionNodeProp(
            this.node,
            CHILD_NODE,
            CHILD_NODE?.type,
            IS_ORDENING_LIST
          );
        }
      }

      // if (this.draggedItem != null) {
      //   const DRAG_ID = event.dataTransfer.getData("itemID");
      //   const IDX = this.node.conditions.findIndex((el) => el.auxId == DRAG_ID);
      //   const DRAG_ITEM = this.node.conditions.splice(IDX, 1)[0];
      //   this.node.conditions.splice(0, 0, DRAG_ITEM);
      //   this.draggedItem = null;
      // } else {
      //   const ITEM_ID = event.dataTransfer.getData("itemID");
      //   console.log(ITEM_ID)
      //   console.log(this.dbConfigTransformedList)
      //   const FATHER_NODE = this.dbConfigTransformedList.find((i) =>
      //     i.children.find((row) => row.auxId == ITEM_ID)
      //   );
      //   const CHILD_NODE = FATHER_NODE.children.find((j) => j.auxId == ITEM_ID);

      //   this.createConditionNodeProp(this.node, CHILD_NODE, CHILD_NODE.type);
      // }

      // console.log(this.node.conditions)
    },
    onDropItem(event, el, idx) {
      const DRAG_ID = event.dataTransfer.getData("itemID");
      const IDX = this.node.conditions.findIndex((el) => el.auxId == DRAG_ID);
      const DRAG_ITEM = this.node.conditions.splice(IDX, 1)[0];
      this.node.conditions.splice(idx, 0, DRAG_ITEM);
      this.draggedItem = null;

      // console.log('DROP-ITEM')
      // if (this.draggedItem != null) {
      //   const DRAG_ID = event.dataTransfer.getData("itemID");
      //   const IDX = this.node.conditions.findIndex((el) => el.auxId == DRAG_ID);
      //   const DRAG_ITEM = this.node.conditions.splice(IDX, 1)[0];
      //   this.node.conditions.splice(idx, 0, DRAG_ITEM);
      //   this.draggedItem = null;
      // } else {
      //   const ITEM_ID = event.dataTransfer.getData("itemID");
      //   const FATHER_NODE = this.dbConfigTransformedList.find((i) =>
      //     i.children.find((row) => row.auxId == ITEM_ID)
      //   );
      //   const CHILD_NODE = FATHER_NODE.children.find((j) => j.auxId == ITEM_ID);

      //   this.createConditionNodeProp(this.node, CHILD_NODE, CHILD_NODE.type);
      // }
    },
    isLastChild(item) {
      const CURRENT_ELEMENT_IDX = this.node.conditions.findIndex(
        (d) => d.auxId == item.auxId
      );

      if (CURRENT_ELEMENT_IDX >= 0) {
        return (
          this.node.conditions[CURRENT_ELEMENT_IDX].auxId ==
          this.node.conditions[this.node.conditions.length - 1].auxId
        );
      }

      return false;
    },
  },
  computed: {
    hasChildren() {
      console.log(this.node.conditions);
      const { nextGroup } = this.node;
      if (nextGroup == null) return false;
      return !this.isEmpty(nextGroup);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  & > div {
    margin-bottom: 0.8rem;
  }
  .content {
    min-height: 57vh;
    max-height: 57vh;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    padding: 0.8rem;
    border-radius: 15px;
    overflow-y: auto;
    @include trackScrollBar;

    .no-data {
      padding: $z-s-2 0;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-weight: 600;
        color: #1976d2;
        font-size: 18px;
      }
    }
    & > div {
      margin-bottom: $z-s-1;
    }
    &__line {
      &__item {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }
    }
    &__condiction {
      margin: 0;

      span {
        font-weight: 600;
        color: #1976d2;
        cursor: pointer;
        font-size: 0.95rem;
      }
    }

    @media (max-width: 990px) {
      min-height: 50vh;
      max-height: 50vh;
    }

    @media (min-width: 1580px) {
      min-height: 64vh;
      max-height: 64vh;
    }
  }
}
</style>
