<template>
  <div class="list">
    <div
      v-for="(group, index) in elements.groups"
      :key="group.auxId || index"
      class="list__item"
    >
      <!-- <BlockItem
        v-if="isFully(index)"
        :node="group"
        :is-last-group-prop="verifyIsLastGroup(index)"
        :create-condition-node-prop="verifyToCreateConditionNode"
        :create-group-prop="verifyToCreateGroup"
        :delete-line-prop="deleteLineOnNode"
        :delete-group-prop="deleteGroup"
      /> -->
      <BlockItem
        :node="group"
        :is-last-group-prop="verifyIsLastGroup(index)"
        :create-condition-node-prop="verifyToCreateConditionNode"
        :create-group-prop="verifyToCreateGroup"
        :delete-line-prop="deleteLineOnNode"
        :delete-group-prop="deleteGroup"
        :pickup-element-prop="pickupElement"
        @loadingLargeData="loadingLargeData"
      />
    </div>
  </div>
</template>

<script>
import BlockItem from "./blockItem.vue";
export default {
  components: {
    BlockItem,
  },
  data() {
    return {
      elements: this.info,
    };
  },
  watch: {
    info(nv) {
      this.elements = nv;
    },
    sendDataProp(nv) {
      if (nv) this.$emit("formContent", this.elements);
    },
    sendDataImpactProp(nv) {
      if (nv) this.$emit("formContentImpact", this.elements);
    },
    sendDataToTableProp(nv) {
      if (nv) this.$emit("formContentTable", this.elements);
    },
  },
  props: {
    info: { type: Object, default: null },
    sendDataProp: {
      type: Boolean,
      default: false,
    },
    sendDataImpactProp: {
      type: Boolean,
      default: false,
    },
    sendDataToTableProp: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loadingLargeData(data) {
      this.$emit("loadingLargeData", data);
    },
    verifyIsLastGroup(currentIndex) {
      const LAST_ITEM = this.elements.groups.length - 1;

      return LAST_ITEM == currentIndex;
    },
    // MÉTODO PARA SÓ MOSTRAR CAIXA ABAIXO, SE A DONCIÇÃO ENTRE BLOCOS ESTIVER PREENCHIDA
    isFully(idx) {
      if (idx > 0 && this.elements.groups[idx - 1].relation !== null) return true;
      // if (idx == 0 && this.elements.groups[idx].relation != null) return true;
      if (idx == 0) return true; // QUANDO FOR 0, SEMPRE RETORNAR TRUE, PORQUE PRECISA SEMPRE TER UM BLOC

      return false;
    },
    generateRandomId() {
      const PART_1 = Math.floor(Math.random() * 245);
      const PART_2 = Date.now();
      return `${PART_1}${PART_2}`;
    },

    pickupElement(event) {
      event.dataTransfer.dropEffect = "move";
      // console.log( event.dataTransfer.dropEffect)
      // console.log(event, pickedNodeChild)
    },

    verifyToCreateConditionNode(upNode, nodeChild, type, isOrnedingList) {
      if (!isOrnedingList) {
        // console.log(upNode, nodeChild, type)
        const RDM_ID = this.generateRandomId();

        const OBJ = {
          field: nodeChild.name,
          configurationId: nodeChild.configurationId,
          configurationType: nodeChild.configurationType,
          type: type,
          value: "",
          originalId: nodeChild.auxId,
          auxId: RDM_ID,
        };
        const FOUNDED_ITEM_ON_FATHER = this.elements.groups.findIndex(
          (el) => el.auxId == upNode.auxId
        );

        if (FOUNDED_ITEM_ON_FATHER >= 0)
          this.elements.groups[FOUNDED_ITEM_ON_FATHER].conditions.push(OBJ);
      }
    },

    verifyToCreateGroup(nodeParent) {
      // CONDIÇÃO PARA CRIAR GRUPO, SE A RELAÇÃO ENTRE BLOCOS PARENTES FOREM ESTABELECIDAS
      // if (nodeParent.relation != null) {
      const ACTUAL_IDX = this.elements.groups.findIndex(
        (item) => item.auxId == nodeParent.auxId
      );

      // SE O ELEMENTO ATUAL FOR O ÚLTIMO, CRIAR UMA CAIXA VAZIA ABAIXO DELE
      if (ACTUAL_IDX == this.elements.groups.length - 1) {
        this.elements.groups.push({
          auxId: this.generateRandomId(),
          conditions: [],
          relation: null,
        });
        // }
      }
    },

    deleteLineOnNode(toDelete, father) {
      const FOUND_NODE_INDEX = father.conditions.findIndex(
        (i) => i.auxId == toDelete.auxId
      );
      const FOUNDE_FATHER_INDEX = this.elements.groups.findIndex(
        (j) => j.auxId == father.auxId
      );

      if (FOUND_NODE_INDEX >= 0 && FOUNDE_FATHER_INDEX >= 0) {
        this.elements.groups[FOUNDE_FATHER_INDEX].conditions.splice(FOUND_NODE_INDEX, 1);
      }
    },

    deleteGroup(node) {
      const FOUND_NODE_INDEX = this.elements.groups.findIndex(
        (i) => i.auxId == node.auxId
      );

      if (
        FOUND_NODE_INDEX >= 0 &&
        this.elements.groups[FOUND_NODE_INDEX + 1]?.conditions.length
      ) {
        this.elements.groups[FOUND_NODE_INDEX + 1].conditions = [];
      } else if (FOUND_NODE_INDEX >= 0) {
        this.elements.groups.splice(+FOUND_NODE_INDEX + 1, 1); // SOMANDO OS VALORES, POIS RECEBO A POSIÇÃO DO ELEMENTO PAI( O QUE TEM O ICONE DE EXCLUIR E A RELAÇÃO)
        this.elements.groups[FOUND_NODE_INDEX].relation = null; // RESETAR A RELAÇÃO AO APAGAR GRUPO POR COMPLETO
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.list {
  // border: 2px solid green;
}
</style>
